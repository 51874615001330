<template>
  <div>

    <!-- View third party modal template -->
    <b-modal ref="view-tp"
             hide-footer
             @hide="hideTPModal"
    >
      <div class="d-block text-center">
        <h4 qid="view-tp-title" v-if="object">
          {{ $t('systems.data_objects.view_activities_for_data_object') }}
        </h4>
        <h4 qid="view-tp-title" v-if="object.data_object">
          {{ object.data_object.name }}
        </h4>
      </div>

      <b-tabs class="mt-3">
        <!-- Basic info tab -->
        <b-tab :title="$t('activities.title')">
          <div class="modal-tabs-scrollable">
            <div v-if="activities" class="mt-3 mb-3">
              <div v-for="object in activities">
                <h5>
                  <router-link
                      :to="{ name: 'activities.view', params: { slug: object.slug, client: object.client }}"
                      qid="s-view-page-link"
                  >
                    {{ object.name }}
                  </router-link>
                </h5>
                <span v-if="object.users && object.users.length" class="mr-2" >
                  {{ $t('system.owners_label') }}:
                </span>
                <span class="mr-1"
                      v-for="user in object.users"
                      qid="p-owners-list"
                >
                  <Avatar :object="user" size="xs"></Avatar>
                </span>
                <span v-if="object.tags_translated && object.tags_translated.length" class="mr-2 ml-2">
                  {{ $t('system.tags_label') }}:
                </span>
                <span class="mr-1"
                      v-for="tag in object.tags_translated"
                      qid="p-tags-list"
                >
                  <span class="badge badge-info mb-1" :title="tag.name_translated">
                    {{ tag.name_translated | truncate(35) }}
                  </span>
                </span>
                <hr>
              </div>

              <div class="text-center" @click="loadDataObjectActivitiesContent()" v-if="showMore && !loading">
                <a>{{ $t('system.show_more') }}</a>
              </div>
              <div class="text-center" v-if="activities && !activities.length && !loading">
                <a>{{ $t('system.no_results') }}</a>
              </div>
              <div class="text-center" v-if="loading">
                <font-awesome-icon icon="spinner" size="lg" class="fa-spin mr-2"/>
                {{ $t('system.loading') }}
              </div>
            </div>
          </div>
        </b-tab>
      </b-tabs>

      <b-button class="float-right"
                variant="success"
                @click="hideTPModal"
                qid="create-p-cancel-button"
      >
        {{ $t('processes.policies.view.close') }}
      </b-button>
      <br>
  </b-modal>

</div>

</template>

<script>
  import piincHttp from '@/util/configureAxios.js'
  import Avatar from '@/components/Avatar.vue';
  export default {
    name: 'DataObjectActivitiesModal',
    data: function() {
      return {
        error: '',
        showInput: false,
        inputLink: '',
        loadingItems: false,
        showArticleDescription: false,
        secondaryDO: [],
        selectedFileObject: {},
        selectedFileIndex: '',
        selectedLinkObject: {},
        selectedLinkIndex: '',
        activities: [],
        page: 1,
        per_page: 10,
        showMore: true,
        loading: true
      }
    },
    props: {
      object: {
        type: [Object,Array]
      },
      showModal: {
        type: Boolean
      },
      active: {
        type: Boolean
      },
      thirdParty: {
        type: Object
      }
    },
    computed: {
      getClient() {
        return this.$store.state.client
      }
    },
    watch: {
      // Watch when prop changes
      showModal: function (value) {
        if (value) {
          this.showTPModal()
        }
      }
    },
    methods: {
      showTPModal() {
        this.activities = []
        this.page = 1
        this.showMore = true
        this.loading = true
        this.loadDataObjectActivitiesContent();
        this.$refs['view-tp'].show()
      },
      hideTPModal() {
        this.error = ''
        this.showArticleDescription = false;
        this.showInput = false;
        this.$emit('closeViewModal');
        this.$refs['view-tp'].hide()
      },
      loadDataObjectActivitiesContent() {
        let self = this;
        piincHttp.get('third-parties/' + this.thirdParty.slug + '/data-objects/' + this.object.id + '/activities', { params:
          {
            page: this.page,
            per_page: this.per_page,
            client: this.getClient.slug
          }
        }).then(function (response) {
          self.page += 1;
          self.loading = false;
          if (response.data && response.data.length < self.per_page) {
            self.showMore = false;
          }
          self.activities.push(...response.data)
        }, function () {
        });
      },
    },
    components: {
      Avatar
    },
    created () {

    }
  }
</script>
