<template>
  <div>
    <div class="card">
      <div class="card-body">
        <div>
          <b-list-group>
            <b-list-group-item
                v-for="activity in items"
                :key="activity.id"
                class="border-left-0 border-right-0 border-top border-bottom-0 pt-4 pb-4"
            >

              <ThirdPartyActivity :changes="true" :activity="activity" v-if="activity.subject_type === 'App\\Maintenance\\ThirdParty\\Models\\ThirdParty'"/>
              <ThirdPartyDataObjectActivity :changes="true" :activity="activity" v-if="activity.subject_type === 'App\\Maintenance\\ThirdParty\\Models\\ThirdPartyDataObject'"/>
              <ThirdPartyValidationActivity :changes="true" :activity="activity" v-if="activity.subject_type === 'App\\Validation\\Models\\Validation'"/>
              <ThirdPartyRiskScenarioActivity :changes="true" :activity="activity" v-if="activity.subject_type === 'App\\Maintenance\\ThirdParty\\Models\\ThirdPartyRiskScenario'"/>
              <ThirdPartyMeasureActivity :changes="true" :activity="activity" v-if="activity.subject_type === 'App\\Maintenance\\ThirdParty\\Models\\ThirdPartyMeasure'"/>
              <ThirdPartyDocumentActivity :changes="true" :activity="activity" v-if="activity.subject_type === 'App\\Maintenance\\ThirdParty\\Models\\ThirdPartyDocument'"/>
              <ThirdPartyContactActivity :changes="true" :activity="activity" v-if="activity.subject_type === 'App\\Maintenance\\ThirdParty\\Models\\ThirdPartyContact'"/>
              <ThirdPartySubdataProcessorActivity :changes="true" :activity="activity" v-if="activity.subject_type === 'App\\Maintenance\\ThirdParty\\Models\\ThirdPartySubdataProcessor'"/>
            </b-list-group-item>

          </b-list-group>
          <hr class="mt-0"/>
        </div>
        <InfiniteLoading @infinite="loadingHandler">
          <template slot="no-results">
            <span>
              {{ $t('system.no_data') }}
            </span>
          </template>
        </InfiniteLoading>
      </div>
    </div>
  </div>
</template>

<script>
  import piincHttp from '@/util/configureAxios.js'
  import ThirdPartyActivity from '@/components/activity/thirdparty/ThirdParty.vue';
  import ThirdPartyDataObjectActivity from '@/components/activity/thirdparty/ThirdPartyDataObject.vue';
  import ThirdPartyValidationActivity from '@/components/activity/thirdparty/ThirdPartyValidation.vue';
  import ThirdPartyRiskScenarioActivity from '@/components/activity/thirdparty/ThirdPartyRiskScenario.vue';
  import ThirdPartyMeasureActivity from '@/components/activity/thirdparty/ThirdPartyMeasure.vue';
  import InfiniteLoading from 'vue-infinite-loading';
  import ThirdPartyDocumentActivity from '@/components/activity/thirdparty/ThirdPartyDocument.vue';
  import ThirdPartyContactActivity from '@/components/activity/thirdparty/ThirdPartyContact.vue';
  import ThirdPartySubdataProcessorActivity from '@/components/activity/thirdparty/ThirdPartySubdataProcessor.vue';
  export default {
    name: 'ThirdPartyHistory',
    data: function() {
      return {
        process: {},
        items: [],
        currentPage: 1,
        totalRows: 0,
        page: 1
      }
    },
    props: {
      object: {
        type: Object,
        required: true
      }
    },
    components: {
      ThirdPartyActivity,
      ThirdPartyDataObjectActivity,
      ThirdPartyValidationActivity,
      InfiniteLoading,
      ThirdPartyRiskScenarioActivity,
      ThirdPartyMeasureActivity,
      ThirdPartyDocumentActivity,
      ThirdPartyContactActivity,
      ThirdPartySubdataProcessorActivity
    },
    computed: {
      getClient() {
        return this.$store.state.client
      }
    },
    watch: {
      // Watch when client changes in store and trigger api reload
      getClient: function () {

      }
    },
    methods: {
      loadingHandler($state) {
        let self = this
        piincHttp.get('activity-log', { params:
          {
            log: this.process.who_am_i + '::' + this.process.id,
            page: this.page,
            client: self.getClient.slug
          }
        }).then(({ data }) => {
          if (data.items.length) {
            self.page += 1;
            self.items.push(...data.items);
            if ($state) {
              $state.loaded();
            }
          } else {
            if ($state) {
              $state.complete();
            }
          }
        });
      }
    },
    created () {
      this.process = this.object;
    }
  }
</script>
