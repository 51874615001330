<template>
  <div>
    <!-- System table -->
    <div class="card pagination-margin" qid="activities-section">
      <div class="card-body">
        <div class="row">
          <div class="col-sm-6">
            <div class="float-left">
              <h5 class="mb-2">{{ $t('activities.title') }}</h5>
              <span class="cursor-pointer task-label-open mr-2"
                    v-if="getTask('update_activities')"
                    @click="openToolbar(getTask('update_activities'))"
              >
                <font-awesome-icon icon="info-circle" class="text-primary"/>
                {{ $t('tasks.open_label') }}
              </span>
              <span v-if="getGuide('update_activities')">
                <GuideButton :guide="getGuide('update_activities')"></GuideButton>
              </span>
            </div>
          </div>
        </div>
        <table class="table table-responsive-md table-list-secondary">
          <thead>
          <tr>
            <th scope="col">
              {{ $t('activities.table.name') }}
            </th>
            <th scope="col">
              {{ $t('activities.table.owners') }}
            </th>
            <th scope="col">
              {{ $t('activities.table.tags') }}
            </th>
            <th scope="col">
              {{ $t('activities.table.organizations') }}
            </th>
            <th scope="col">
              {{ $t('activities.table.process_groups') }}
            </th>
            <th scope="col">
              {{ $t('activities.table.countries') }}
            </th>
            <th scope="col">
              {{ $t('system.last_updated') }}
            </th>
          </tr>
          </thead>
          <tbody>
            <tr v-for="object in tableItems">
              <td>
                <router-link
                    :to="{ name: 'activities.view', params: { slug: object.activity.slug, client: object.activity.client }}"
                    qid="s-view-page-link"
                    v-if="object.activity.client == getClient.slug"
                >
                  {{ object.activity.name }}
                </router-link>
                <button v-if="object.activity.client != getClient.slug"
                        class="btn btn-link btn-sm"
                        @click="openNoticeModal(object.activity)"
                >
                  {{ object.activity.name }}
                </button>
              </td>
              <td>
                <span class="mr-1" v-for="user in object.activity.users">
                  <span qid="profile-photo" v-b-tooltip.hover :title="user.name">
                    <Avatar :object="user" size="xs"></Avatar>
                  </span>
                </span>
              </td>
              <td>
                <span class="mr-1" v-for="tag in object.activity.tags_translated" qid="p-tags-list">
                  <span class="badge mb-1" :title="tag.name_translated">
                    <font-awesome-icon icon="tag"/>
                    {{ tag.name_translated | truncate(35) }}
                  </span>
                </span>
              </td>
              <td>
                <span class="mr-1" v-for="organization in object.activity.organizations" qid="p-organizations-list">
                  <span class="badge" :title="organization.name">
                    <font-awesome-icon icon="sitemap"/>
                    {{ organization.name | truncate(35) }}
                  </span>
                </span>
              </td>
              <td>
                <span class="mr-1" v-for="group in object.activity.process_groups" qid="p-countries-list">
                  <span class="badge mb-1" :title="group.name">
                    <font-awesome-icon icon="project-diagram"/>
                    {{ group.name | truncate(35) }}
                  </span>
                </span>
              </td>
              <td>
                <span class="mr-1" v-for="country in object.countries_with_flag" qid="p-countries-list">
                  <span class="badge">
                    <img v-bind:src="country.flag" height="13" class="align-baseline mr-1" />
                    <span>{{country.name}}</span>
                  </span>
                </span>
              </td>
              <td>
                <span class="text-normal">
                  {{ object.activity.updated_at | formatDate }}
                </span>
              </td>
            </tr>
            <tr v-if="!loading && !tableItems.length" qid="no-results-row" class="text-center">
              <td colspan="10">
                <h5 class="mt-3">
                  {{ $t('system.no_results') }}
                </h5>
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <div class="card-body">
        <div class="pagination row">
          <div class="col-md-6">
            <div class="row">
              <div class="float-left">
                <span class="text-muted pagination-counter">
                  {{ $t('pagination.entries_per_page') }}:
                </span>
              </div>
              <div class="float-left">
                <multiselect
                    v-model="perPage"
                    :options="perPageOptions"
                    track-by="value"
                    label="value"
                    :select-label="$t('system.dropdown_select_label')"
                    :selected-label="$t('system.dropdown_selected_label')"
                    :deselect-label="$t('system.dropdown_deselect_label')"
                    qid="create-org-form-organization-level"
                    @select="perPageChanged"
                    :placeholder="selectedPerPage"
                    @remove="perPageRemoved"
                ></multiselect>
              </div>
            </div>
          </div>
          <div class="col-md-6">
            <b-pagination
                class="float-right"
                v-model="currentPage"
                :total-rows="rows"
                :per-page="selectedPerPage"
                aria-controls="my-table"
                @change="pageChanged"
                limit="1"
            >
              <span class="text-muted" slot="first-text"><font-awesome-icon icon="angle-double-left"/></span>
              <span class="text-muted" slot="prev-text"><font-awesome-icon icon="angle-left"/></span>
              <span class="text-muted" slot="next-text"><font-awesome-icon icon="angle-right"/></span>
              <span class="text-muted" slot="last-text"><font-awesome-icon icon="angle-double-right"/></span>
              <div slot="ellipsis-text">
                <b-spinner small type="grow"></b-spinner>
                <b-spinner small type="grow"></b-spinner>
                <b-spinner small type="grow"></b-spinner>
              </div>
              <span class="text-muted" slot="page" slot-scope="{ page, active }">
                <span v-if="active">{{getPerPage}}</span>
              </span>
            </b-pagination>
          </div>
        </div>
      </div>
    </div>


    <PartitionNoticeModal
        :item="selectedItem"
        state="activities.view"
        :show="showNoticeModal"
        @closeViewModal="closeNoticeModal"
    ></PartitionNoticeModal>

  </div>

</template>

<script>
  import piincHttp from '@/util/configureAxios.js'
  import Avatar from '@/components/Avatar.vue';
  import PartitionNoticeModal from '@/components/PartitionNoticeModal.vue';
  import GuideButton from '@/components/guide/GuideButton.vue';


  export default {
    name: 'ActivitiesTable',
    data: function() {
      return {

        // Systems
        tableItems: [],
        selectedS: [],
        viewSModal: false,
        selectedSSort: '',
        selectedSDirection: '',
        pItems: [],
        sLoading: true,

        error: '',
        loadingItems: false,
        itemToDelete: {},
        itemObject: {},
        selectedP: [],
        showSDescription: false,

        currentPage: 1,
        totalRows: 0,
        selectedSort: '',
        selectedDirection: '',
        keywordValue: '',
        myGuides: [],

        loading: true,
        perPage: '',
        selectedPerPage: "10",
        perPageOptions: [
          { name: '10', value: '10' },
          { name: '20', value: '20' },
          { name: '50', value: '50' },
          { name: '100', value: '100' },
        ],
        showNoticeModal: false,
        selectedItem: {}
      }
    },
    props: {
      object: {
        type: Object,
        required: true
      }
    },
    components: {
      Avatar,
      PartitionNoticeModal,
      GuideButton
    },
    computed: {
      getClient() {
        return this.$store.state.client
      },
      rows() {
        // Use computed value for rows because of dynamic update
        return this.totalRows
      },
      getStorePerPage() {
        return this.$store.state.perPage
      },
      getPerPage() {
        let page = this.currentPage;
        let selectedFrom = (page-1) * Number(this.selectedPerPage) + 1;
        let selectedTo = (page-1) * Number(this.selectedPerPage) + Number(this.selectedPerPage);
        let selectedOf = this.totalRows;

        if(selectedTo > selectedOf){
          selectedTo = selectedOf
        }

        return selectedFrom + " - " + selectedTo + " " + this.$t('system.of') + " " + selectedOf
      }
    },
    watch: {
      // Watch when prop changes and then load validations
      object: function (value) {
        this.loadContent()
      }
    },
    methods: {
      getTask(code) {
        let existingTask = _.find(this.tasks, function(object) {
          return object.code == code
        });
        return existingTask
      },
      openNoticeModal(object) {
        this.selectedItem = object
        this.showNoticeModal = true
      },
      closeNoticeModal() {
        this.selectedItem = {}
        this.showNoticeModal = false
      },
      openToolbar(object) {
        this.$store.commit('storeTaskToolbarState', true);
        this.$store.commit('storeActiveTask', object);
      },
      getGuide(code) {
        let existingGuide = _.find(this.myGuides, function(object) {
          return object.scope == code && object.type == 'Third party' && object.enabled
        });
        return existingGuide
      },
      loadGuides(code) {
        let self = this;
        let language = localStorage.getItem('lang')? localStorage.getItem('lang') : 'en';

        piincHttp.get('guides', { params:
          {
            per_page: 1,
            language: 'en',
            type: 'Third party',
            scope: code,
            client: this.getClient.slug
          }
        }).then(function(response) {
          if (response.data.items.length) {
            self.myGuides.push(response.data.items[0])
          }
        }, function() {});
      },
      openViewSModal(object) {
        this.selectedS = object
        this.viewSModal = true
      },
      handleCloseViewSModal () {
        this.selectedS = []
        this.viewSModal = false;
      },
      pageChanged(page) {
        // Load Third parties when page changed listener is triggered
        this.loadContent(page)
      },
      perPageChanged(perPage) {
        let self = this;

        self.selectedPerPage = perPage.value
        this.$store.commit('storePerPage', this.selectedPerPage);
        // Load Third parties when page changed listener is triggered
        this.loadContent(1, perPage.value)
      },
      perPageRemoved() {
        let self = this;
        // Load Third parties when page changed listener is triggered
        self.selectedPerPage = self.perPageOptions[0].value
        this.loadContent(1, self.selectedPerPage)
      },
      loadContent(page, perPage) {
        let self = this;

        if (this.getStorePerPage) {
          this.selectedPerPage = this.getStorePerPage;
        }

        // If page is not defined, set page to default 1
        let pageNumber = page ? page : 1;
        let perPageNumber = perPage ? perPage : this.selectedPerPage;
        if (this.object && this.object.id) {
          piincHttp.get('third-parties/' + this.object.slug + '/activities', { params:
            {
              page: pageNumber,
              per_page: perPageNumber,
              client: this.getClient.slug
            }
          }).then(function (response) {
            self.loading = false;
            self.tableItems = response.data.items;
            // Set number of total items
            self.totalRows = response.data.total_count;
          }, function () {
          });
        }
      },
    },
    created () {
      // Table items
      this.loadContent();
      this.loadGuides('update_activities');
    }
  }
</script>
