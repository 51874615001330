<template>

<div qid="integrity-check-section"
     id="update_transfers_or_disclosures"
>
  <br>
  <div class="card">
    <div class="card-header">
      <div class="float-right">
        <span class="btn btn-success"
              @click="showFormFunction"
              qid="risk-edit-button"
              v-if="isGranted([permissions.THIRD_PARTY_EDIT, permissions.THIRD_PARTY_EDIT_OWN], thirdParty) && (!getClient.parent || (getClient.parent && thirdParty.partition))"
        >
          <font-awesome-icon icon="pen"/> {{ $t('system.edit') }}
        </span>
      </div>
      <div class="float-left">
        <h5 class="mb-2" qid="integrity-check-table-title">
          {{ $t('maintenance.third_parties.create.transfers_disclosures_third_countries_label') }}
        </h5>
        <span class="cursor-pointer task-label-open mr-2"
              v-if="getTask('update_transfers_or_disclosures')"
              @click="openToolbar(getTask('update_transfers_or_disclosures'))"
        >
          <font-awesome-icon icon="info-circle" class="text-primary"/>
          {{ $t('tasks.open_label') }}
        </span>
        <span v-if="getGuide('update_transfers_or_disclosures')">
          <GuideButton :guide="getGuide('update_transfers_or_disclosures')"></GuideButton>
        </span>
      </div>
    </div>

    <div class="row no-gutters">
      <div class="col-sm-2 card-menu">
        <div class="card-body text-dark">
          <span class="mt-2">
            {{ $t('maintenance.third_parties.transfers_disclosures_third_countries.third_countries_label') }}
          </span>
        </div>
      </div>
      <div class="col-sm-10">
        <div class="card-body">
          <div class="mw-500" v-if="showForm">
            <multiselect
                v-model="form.transfers_disclosures_third_countries"
                :options="transfersDisclosuresThirdCountries"
                :select-label="$t('system.dropdown_select_label')"
                :selected-label="$t('system.dropdown_selected_label')"
                :deselect-label="$t('system.dropdown_deselect_label')"
                :placeholder="$t('maintenance.third_parties.transfers_disclosures_third_countries.third_countries_label')"
                label="name"
                qid="edit-tp-form-data-processor"
            ></multiselect>
          </div>
          <div v-if="!showForm">
            <div v-if="thirdParty">
              <p qid="risk-dpia">
                <span qid="tp-inspection" v-if="thirdParty.transfers_disclosures_third_countries">
                  {{ $t('maintenance.third_parties.transfers_disclosures_third_countries.' + $options.filters.slugify(thirdParty.transfers_disclosures_third_countries)) }}
                </span>
                <span class="ml-3"
                      v-if="thirdParty.transfers_disclosures_third_countries && thirdParty.transfers_disclosures_third_countries == 'Yes'"
                >
                  <span v-for="country in thirdCountries">
                    <span class="mr-1 badge badge-info mb-1">{{country.name}}</span>
                  </span>
                </span>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="row no-gutters">
      <div class="col-sm-2 card-menu">
        <div class="card-body text-dark">
          <span class="mt-2">
            {{ $t('maintenance.third_parties.transfers_disclosures_third_countries.contract_type_label') }}
          </span>
        </div>
      </div>
      <div class="col-sm-10">
        <div class="card-body">
          <div class="mw-500" v-if="showForm">
            <multiselect
                v-model="form.third_country_contract_type"
                :options="thirdCountryContractTypes"
                :placeholder="$t('maintenance.third_parties.transfers_disclosures_third_countries.contract_type_label')"
                label="name"
                :select-label="$t('system.dropdown_select_label')"
                :selected-label="$t('system.dropdown_selected_label')"
                :deselect-label="$t('system.dropdown_deselect_label')"
                qid="create-tp-form-data-processor"
            ></multiselect>
          </div>
          <div v-if="!showForm">
            <div v-if="thirdParty">
              <span qid="tp-inspection" v-if="thirdParty.third_country_contract_type">
                {{ $t('maintenance.third_parties.third_country_contract_type.' + $options.filters.slugify(thirdParty.third_country_contract_type)) }}
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="row no-gutters">
      <div class="col-sm-2 card-menu">
        <div class="card-body text-dark">
          <span class="mt-2">
            {{ $t('maintenance.third_parties.transfers_disclosures_third_countries.contract_date_label') }}
          </span>
        </div>
      </div>
      <div class="col-sm-10">
        <div class="card-body">
          <div class="mw-500" v-if="showForm">
            <datepicker
                class="date-picker"
                qid="edit-tp-form-dpa-updated-at"
                v-model="form.third_country_contract_date"
                :clear-button="true"
                :placeholder="$t('maintenance.third_parties.transfers_disclosures_third_countries.contract_date_label')"
            ></datepicker>
          </div>
          <div v-if="!showForm">
            <div v-if="thirdParty">
              <div qid="risk-dpia">
                <span qid="tp-inspection" v-if="thirdParty.third_country_contract_date">
                  {{ thirdParty.third_country_contract_date | formatDate  }}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- <div class="row no-gutters">
      <div class="col-sm-2 card-menu">
        <div class="card-body text-dark">
          <span class="mt-2">
            {{ $t('maintenance.third_parties.create.country_label') }}
          </span>
        </div>
      </div>
      <div class="col-sm-10">
        <div class="card-body">
          <div class="mw-500" v-if="showForm">
            <multiselect
                v-model="selectedCountries"
                :options="countries"
                :multiple="true"
                :taggable="false"
                :select-label="$t('system.dropdown_select_label')"
                :selected-label="$t('system.dropdown_selected_label')"
                :deselect-label="$t('system.dropdown_deselect_label')"
                track-by="id"
                :placeholder="$t('maintenance.third_parties.create.country_placeholder')"
                label="name"
                qid="edit-tp-countries"
            ></multiselect>
          </div>
          <div v-if="!showForm">
            <div v-if="thirdParty">
              <div qid="risk-dpia">
                <span class="mr-1" v-for="country in thirdParty.countries">
                  <span class="badge badge-info mb-1">{{country}}</span>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div> -->

    <div class="row no-gutters">
      <div class="col-sm-2 card-menu">
        <div class="card-body text-dark">
          <span>
            {{ $t('maintenance.third_parties.create.dpa_description_label') }}
          </span>
        </div>
      </div>
      <div class="col-sm-10">
        <div class="card-body">
          <div class="mw-500" v-if="showForm">
            <b-form-textarea
              qid="risk-action-description"
              v-model="form.transfers_disclosures_third_countries_description"
              rows="4"
              max-rows="4"
              :placeholder="$t('maintenance.third_parties.create.dpa_description_placeholder')"
            ></b-form-textarea>
            <div class="text-right">
              <b-button class="mt-3"
                        variant="secondary"
                        @click="cancelForm"
                        qid="risk-form-cancel-button"
              >
                {{ $t('processes.legal_basis.create.cancel') }}
              </b-button>
              <b-button class="mt-3 ml-3"
                        variant="success"
                        @click="formSubmit"
                        qid="risk-form-submit-button"
              >
                {{ $t('processes.legal_basis.create.submit') }}
              </b-button>
            </div>
          </div>
          <div v-if="!showForm">
            <div class="text-formatted"
                 qid="risk-action-plan"
                 style="min-height: 105px;"
            >
              {{ thirdParty.transfers_disclosures_third_countries_description }}
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="row no-gutters">
      <div class="col-sm-2 card-menu">
        <div class="card-body text-dark">
          <span>
            {{ $t('attachments.label') }}
            <br>
            <span>({{ $t('attachments.file_size_label') }})</span>
          </span>
        </div>
      </div>
      <div class="col-sm-10">
        <div class="card-body">
          <div v-if="!showForm" class="mw-500">
            <b-alert v-if="error.length"
                     show
                     variant="danger"
                     qid="attachments-p-error"
            >
              {{ error }}
            </b-alert>
            <p v-for="file in thirdParty.media">
              <span v-if="file.collection_name == 'third_countries'">
                <span class="float-right text-muted cursor-pointer"
                      @click="openDeleteAttachmentModal(file)"
                      qid="remove-media-item"
                      v-if="isGranted([permissions.THIRD_PARTY_EDIT, permissions.THIRD_PARTY_EDIT_OWN], thirdParty) && (!getClient.parent || (getClient.parent && thirdParty.partition))"
                >
                  <font-awesome-icon icon="trash-alt" />
                </span>
                <span class="mr-3 float-right text-muted cursor-pointer"
                      @click="downloadMedia(file)"
                      qid="download-media"
                >
                  <font-awesome-icon icon="cloud-download-alt" />
                </span>
                <a @click="downloadMedia(file)" qid="create-p-media-item">
                  {{ file.file_name }}
                </a>
              </span>
            </p>
            <label v-if="isGranted([permissions.THIRD_PARTY_EDIT, permissions.THIRD_PARTY_EDIT_OWN], thirdParty) && (!getClient.parent || (getClient.parent && thirdParty.partition))"
                   class="btn btn-secondary cursor-pointer"
                   qid="create-p-file-button"
            >
              <input type="file"
                     name="fileupload"
                     value="fileupload"
                     multiple
                     id="file"
                     @change="onFileChange"
                     @click="resetFile"
              >
              <span>
                <font-awesome-icon icon="cloud-upload-alt" />
                {{ $t('system.upload') }}
              </span>
            </label>
          </div>
        </div>
      </div>
    </div>

    <div class="row no-gutters">
      <div class="col-sm-2 card-menu">
        <div class="card-body text-dark">
          <span>{{ $t('links.label') }}</span>
        </div>
      </div>
      <div class="col-sm-10">
        <div class="card-body">
          <div class="mw-500" v-if="!showForm">
            <div v-if="thirdParty.links">
              <p v-for="link in thirdParty.links" >
                <span v-if="link.collection == 'third_countries'">
                  <span class="float-right text-muted cursor-pointer"
                        @click="openDeleteLinkModal(link)"
                        qid="view-p-remove-link"
                        v-if="isGranted([permissions.THIRD_PARTY_EDIT, permissions.THIRD_PARTY_EDIT_OWN], thirdParty) && (!getClient.parent || (getClient.parent && thirdParty.partition))"
                  >
                    <font-awesome-icon icon="trash-alt" />
                  </span>
                  <a :href="link.url" target="_blank" qid="create-p-link-item">
                    {{ link.name ? link.name : link.url }}
                  </a>
                </span>
              </p>
            </div>
            <div v-if="showInput">
              <label class="mt-2">{{ $t('system.link') }}</label>
              <b-form-input
                type="text"
                aria-describedby="inputGroup"
                v-model="inputLink"
                :placeholder="$t('maintenance.third_parties.create.links_placeholder')"
                qid="create-p-links"
              ></b-form-input>
              <label class="mt-2">{{ $t('table.name') }}</label>
              <b-form-input
                type="text"
                aria-describedby="inputGroup"
                v-model="inputLinkName"
                :placeholder="inputLink ? inputLink : $t('table.name')"
                qid="create-p-links"
              ></b-form-input>
              <div class="text-right mt-2">
                <span class="btn btn-success cursor-pointer mr-0"
                      id="inputGroup"
                      @click="handleLink"
                      qid="handle-link-save"
                >
                  {{ $t('system.save') }}
                </span>
              </div>
            </div>
            <span class="btn btn-link cursor-pointer"
                  @click="showInput = true"
                  qid="add-another-link"
                  v-if="isGranted([permissions.THIRD_PARTY_EDIT, permissions.THIRD_PARTY_EDIT_OWN], thirdParty) && (!getClient.parent || (getClient.parent && thirdParty.partition))"
            >
              <font-awesome-icon icon="plus"/> {{ $t('system.add_link') }}
            </span>
          </div>
        </div>
      </div>
    </div>

  </div>

  <ConfirmRemoveAttachment
      :object="selectedFile"
      :show="showDeleteAttachmentModal"
      type="file"
      @closeConfirmModal="closeDeleteAttachmentModal"
  ></ConfirmRemoveAttachment>

  <ConfirmRemoveAttachment
      :object="selectedLink"
      :show="showDeleteLinkModal"
      type="link"
      @closeConfirmModal="closeDeleteLinkModal"
  ></ConfirmRemoveAttachment>

</div>

</template>

<script>
  import piincHttp from '@/util/configureAxios.js'
  import ValidationForm from '@/views/Validations/ValidationForm.vue';
  import ValidationList from '@/views/Validations/ValidationList.vue';
  import ConfirmRemoveAttachment from '@/components/ConfirmRemoveAttachment.vue';
  import Datepicker from 'vuejs-datepicker';
  import GuideButton from '@/components/guide/GuideButton.vue';


  export default {
    name: 'TransfersDisclosures',
    data: function() {
      return {
        form: {
          transfers_disclosures_third_countries: [],
          third_country_contract_type: [],
          third_country_contract_date: '',
          transfers_disclosures_third_countries_description: '',
        },
        description: '',
        error: '',
        loadingItems: false,
        showForm: false,
        icItems: [],
        transfersDisclosuresThirdCountries : [
          { name: this.$t('maintenance.third_parties.transfers_disclosures_third_countries.no'), value: 'No' },
          { name: this.$t('maintenance.third_parties.transfers_disclosures_third_countries.yes'), value: 'Yes' },
          { name: this.$t('maintenance.third_parties.transfers_disclosures_third_countries.n/a'), value: 'N/A' }
        ],
        thirdCountryContractTypes: [
          { name: this.$t('maintenance.third_parties.third_country_contract_type.contract_model_clause'), value: 'Contract model clause' },
          { name: this.$t('maintenance.third_parties.third_country_contract_type.eu/us_privacy_shield'), value: 'EU/US Privacy Shield' },
          { name: this.$t('maintenance.third_parties.third_country_contract_type.adequacy_decision'), value: 'Adequacy decision' },
          { name: this.$t('maintenance.third_parties.third_country_contract_type.binding_corporate_rules'), value: 'Binding corporate rules' }
        ],
        selectedCountries: [],
        countries: [],
        thirdParty: {},
        showInput: false,
        inputLink: '',
        inputLinkName: '',
        selectedFile: {},
        selectedLink: {},
        showDeleteAttachmentModal: false,
        showDeleteLinkModal: false,
        thirdCountries: [],
        myGuides: []
      }
    },
    props: {
      object: {
        type: Object
      },
      tasks: {
        type: Array
      }
    },
    computed: {
      getClient() {
        return this.$store.state.client
      }
    },
    components: {
      ValidationForm,
      ValidationList,
      Datepicker,
      ConfirmRemoveAttachment,
      GuideButton
    },
    watch: {
      object: function (value) {
        this.thirdParty = value
        this.setThirdCountries()
      }
    },
    methods: {
      getTask(code) {
        let existingTask = _.find(this.tasks, function(object) {
          return object.code == code
        });
        return existingTask
      },
      openToolbar(object) {
        this.$store.commit('storeTaskToolbarState', true);
        this.$store.commit('storeActiveTask', object);
      },
      getGuide(code) {
        let existingGuide = _.find(this.myGuides, function(object) {
          return object.scope == code && object.type == 'Third party' && object.enabled
        });
        return existingGuide
      },
      loadGuides(code) {
        let self = this;
        let language = localStorage.getItem('lang')? localStorage.getItem('lang') : 'en';

        piincHttp.get('guides', { params:
          {
            per_page: 1,
            language: 'en',
            type: 'Third party',
            scope: code,
            client: this.getClient.slug
          }
        }).then(function(response) {
          if (response.data.items.length) {
            self.myGuides.push(response.data.items[0])
          }
        }, function() {});
      },
      openDeleteAttachmentModal(object) {
        this.selectedFile = object
        this.showDeleteAttachmentModal = true
      },
      closeDeleteAttachmentModal () {
        this.selectedFile = {}
        this.showDeleteAttachmentModal = false;
        this.$emit('updated');
      },
      openDeleteLinkModal(object) {
        this.selectedLink = object
        this.showDeleteLinkModal = true
      },
      closeDeleteLinkModal () {
        this.selectedLink = {}
        this.showDeleteLinkModal = false;
        this.$emit('updated');
      },
      resetFile() {
        let imagefile = document.querySelector('#file');
        imagefile.value = '';
      },
      handleLink() {
        let self = this;
        let formData = new FormData();

        if (!this.inputLink.startsWith('http')) {
          this.inputLink = 'http://' + this.inputLink
        }

        formData.append("url", this.inputLink);
        formData.append("name", this.inputLinkName);
        formData.append("model_type", this.thirdParty.who_am_i);
        formData.append("model_id", this.thirdParty.id);
        formData.append("collection", 'third_countries');

        piincHttp.post('links', formData).then(function() {
          self.$toastr('success', self.$t('maintenance.third_parties.link_add_success'))
          self.$emit('updated');
          self.inputLink = ''
          self.inputLinkName = ''
          self.showInput = false
        }, function() {})
      },
      downloadMedia(object){
        piincHttp.get('documents/' + object.id + '/download', {responseType: 'blob'}).then(function(response) {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement('a');
          link.href = url;
          link.download = object.file_name;

          link.click();
          link.remove()
          window.URL.revokeObjectURL(url);
        }, function() {});
      },
      onFileChange(file) {
        let files = file.target.files;
        let self = this;
        let formData = new FormData();
        let sizeError = false;

        for (var i = 0; i < files.length; i++) {
          // Equal to 10 MB
          if (files[i].size > 10485760) {
            sizeError = true;
          }
          formData.append('document[]', files[i]);
        }

        formData.append("name", "documents");
        formData.append("model_type", this.thirdParty.who_am_i);
        formData.append("model_id", this.thirdParty.id);
        formData.append("collection", 'third_countries');

        if (sizeError) {
          this.error = this.$t('system.file_size')
        } else {
          piincHttp.post('documents', formData, {
            headers: {
              'Content-Type': 'multipart/form-data'
            }
          }).then(function() {
            self.$toastr('success', self.$t('maintenance.third_parties.media_add_success'))
            self.error = ''
            self.$emit('updated');
          }, function(error) {
            self.error = error.data.message;
            if (error.data.errors['document.0']) {
              self.error += ' ' + error.data.errors['document.0'][0]
            }
          })
        }
      },
      setThirdCountries() {
        let self = this;
        this.thirdCountries = [];
        _.forEach(this.thirdParty.countries, function (value, key) {
          _.find(self.countries, function(item) {
            if (item.name == value && !item.eea) {
              self.thirdCountries.push(item)
            }
          });
        })
      },
      formSubmit(evt) {
        // Keep the reference in a variable
        // to use it inside function called when promise returned
        let self = this;
        var moment = require('moment');
        evt.preventDefault();

        this.form.transfers_disclosures_third_countries = this.form.transfers_disclosures_third_countries ? this.form.transfers_disclosures_third_countries.value : '';
        this.form.third_country_contract_type = this.form.third_country_contract_type ? this.form.third_country_contract_type.value : '';
        this.form.third_country_contract_date = this.form.third_country_contract_date ? moment(this.form.third_country_contract_date).format('YYYY-MM-DD') : ''

        // for (var i = 0; i < this.selectedCountries.length; i++) {
        //   this.form.countries.push(this.selectedCountries[i].name);
        // }

        piincHttp.put('third-parties/' + this.thirdParty.slug, this.form).then(function(response) {
          self.thirdParty = response.data
          self.$emit('updated');
          self.showForm = false;
          self.$toastr('success', self.$t('maintenance.third_parties.update.success'))
        }, function(error) {
          if (error.status !== 200) {
            self.error = error.data.message;
          }
        })
      },
      cancelForm() {
        this.showForm = false
      },
      showFormFunction() {
        this.setDefaultForm(this.thirdParty)
        this.showForm = true
      },
      setDefaultForm(object) {
        let self = this;

        this.form.transfers_disclosures_third_countries = []
        if (object.transfers_disclosures_third_countries) {
          this.form.transfers_disclosures_third_countries = _.find(this.transfersDisclosuresThirdCountries, function(item) {
            return item.value === object.transfers_disclosures_third_countries;
          });
        }

        this.form.third_country_contract_type = []
        if (object.third_country_contract_type) {
          this.form.third_country_contract_type = _.find(this.thirdCountryContractTypes, function(item) {
            return item.value === object.third_country_contract_type;
          });
        }

        this.form.third_country_contract_date = object.third_country_contract_date;
        this.form.transfers_disclosures_third_countries_description = object.transfers_disclosures_third_countries_description;
      },
      loadCountries() {
        let self = this;
        piincHttp.get('countries').then(function(response) {
          self.countries = response.data
          self.setThirdCountries()
        }, function() {});
      },
    },
    created () {
      if (this.getClient && this.getClient.slug) {

      }
      this.thirdParty = this.object
      this.loadCountries()
      this.loadGuides('update_transfers_or_disclosures');
    }
  }
</script>
